import React, { useEffect } from 'react';
import './App.css';
import LoadingModal, { handleSignup, handleLogin } from './LoadingModal';

const App = () => {

  // https://pui-auth-test.netlify.app/?signUp=true&userId=something

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signUp = urlParams.get('signUp');
    const logIn = urlParams.get('logIn');
    const userName = urlParams.get('userId');

    //const userId = urlParams.get('userId');


    if (signUp === 'true' && userName) {
      handleSignup(userName);
    } else if (logIn === 'true') {
      handleLogin(userName);
    }
  }, []);

  return (
    <div className="App">
      <LoadingModal />
    </div>
  );
};

export default App;